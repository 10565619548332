.app {
  text-align: left;
  /* background: linear-gradient(to bottom, #fec79d, #e669ba); */
}

.app * {
  color: #333333;
}

.app-header {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  gap: 5vw;
  align-items: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

nav {
  padding-left: 10vw;
  padding-right: 10vw;
  padding-top: 30px;
  display: flex;
  justify-content: space-between;
}

.app-icon-container {
  width: 150px;
  padding-bottom: 15px;
}

.app-icon-container img {
  width: 100%;
}

.app-explainer-container {
  display: flex;
  flex-direction: column;
}

.nav-divider {
  height: 0.25px;
  /* margin-top: 25px; */
  background-color: rgba(91, 97, 110, 0.3);
}

img.landing-page-explainer {
  width: calc(17vw);
}

.medium {
  font-weight: 500;
}

.semi-bold {
  font-weight: 600;
}

.no-margin {
  margin: 0;
}

.center-text {
  text-align: center;
}

.img-goal-setup {
  padding-bottom: 5vh;
  padding-right: 2vw;
}
