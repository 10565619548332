.email-form {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  border: 2.5px solid #007bff;
  border-radius: 35px;
  max-width: 400px;
  padding: 10px;
  margin: 30px 0;
}

.email-input {
  flex-grow: 1;
  border: none;
  outline: none;
  border-radius: 20px;
  padding: 10px;
  margin-right: 10px;
}

.email-input::placeholder {
  color: #909090;
  opacity: 0.7;
}

.submit-btn {
  padding: 10px 20px;
  border: none;
  outline: none;
  background-color: #007bff;
  color: white;
  border-radius: 20px;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
}

.submit-btn:hover {
  background-color: #0056b3;
}
